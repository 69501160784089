import 'antd/dist/antd.css';
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AppLayout } from './layout';
import { PageNotFound } from './pages/page-not-found';
import { UserContextProvider } from './contexts/userContext';
import { CompaniesAndCountriesContextProvider } from './contexts/companiesAndCountriesContext';

import TagManager from 'react-gtm-module';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const tagManagerArgs = {
  gtmId: 'GTM-MLNT5CK'
};


if (!isLocalhost) {
  TagManager.initialize(tagManagerArgs);
}

export function App() {
  return (
    <UserContextProvider>
      <CompaniesAndCountriesContextProvider>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/page-not-found"
              render={(props) => {
                const { state } = props.location;
                const from = state && state.from;
                return <PageNotFound from={from} />;
              }}
            />
            <Route path="/" component={AppLayout} />
          </Switch>
        </BrowserRouter>
      </CompaniesAndCountriesContextProvider>
    </UserContextProvider>
  );
}
