import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Input, Select, Popover, InputNumber } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { AutoComplete } from './AutoComplete';
import { Decimal } from 'decimal.js-light';
import styles from '../../styles/Registers.module.css';
//Default is to precision 20, and rounding half up - values for rounding  0 to 8, 4 means half up
// Decimal.config({ precision: 20, rounding: 4 });
const { Option } = Select;

const ItemsFormFunction = ({ saveItem, registers }, ref) => {
  const [itemName, setItemName] = useState('');
  const [codeName, setCodeName] = useState('');
  const [focus, setFocus] = useState(false);
  const [itemsForm] = Form.useForm();
  const onSubmit = async (values) => {
    await saveItem({ values });
    setItemName('');
    setCodeName('');
    setFocus(true);
    await setTimeout(() => {
      itemsForm.resetFields();
    }, 100);
  };
  useImperativeHandle(
    ref,
    () => ({
      checkItemsForm: async () => {
        {
          const values = itemsForm.getFieldsValue();
          for (const [key, value] of Object.entries(values)) {
            if (key === 'tax' || key ==='discount') {
              continue;
            }
            if (value) {
                try{
                  await itemsForm.validateFields();
                  await itemsForm.submit();
                  return true;

                }catch{
                  return false;
                }
            }
          }
          return true;
        }
      }
    }),
    []
  );
  const onFormChange = (changedFields, allFields) => {
    const fieldName = Object.keys(changedFields)[0];
    if (['unit_price', 'qty', 'tax', 'discount'].includes(fieldName)) {
      setTotal({ ...allFields });
    } else if (Object.keys(changedFields)[0] == 'amount') {
      if (allFields.qty == 0) {
        allFields.qty = 1;
      }
      setPrice({ ...allFields });
    }
  };
  const setTotal = ({ unit_price = 0, qty = 1, tax = 0, discount = 0 }) => {
    let discountDecimal = !isNaN(parseFloat(discount)) ? new Decimal(1).minus(parseFloat(discount) / 100).valueOf() : 1;
    let taxDecimal = tax && !isNaN(tax) ? new Decimal(1).plus(tax / 100).valueOf() : 1;
    let amount = new Decimal(!isNaN(parseFloat(unit_price)) ? parseFloat(unit_price) : 0)
      .times(!isNaN(parseFloat(qty)) ? parseFloat(qty) : 1)
      .times(discountDecimal)
      .times(taxDecimal)
      .toFixed(4)
      .replace(/0{1,2}$/, '');
    itemsForm.setFieldsValue({ amount, qty });
  };
  const setPrice = ({ amount = 0, qty = 1, tax = 0, discount = 0 }) => {
    let discountDecimal = !isNaN(parseFloat(discount)) ? new Decimal(1).minus(parseFloat(discount) / 100).valueOf() : 1;
    let taxDecimal = tax && !isNaN(tax) ? new Decimal(1).plus(tax / 100).valueOf() : 1;
    let unit_price = new Decimal(!isNaN(parseFloat(amount)) ? parseFloat(amount) : 0)
      .dividedBy(taxDecimal)
      .dividedBy(discountDecimal)
      .dividedBy(!isNaN(parseFloat(qty)) && parseFloat(qty) != 0 ? parseFloat(qty) : 1)
      .toFixed(4)
      .replace(/0{1,2}$/, '');
    itemsForm.setFieldsValue({ unit_price, qty });
  };
  const onChangeValue = (a, value) => {
    if (a === 'name') {
      setItemName(value);
    } else {
      setCodeName(value);
    }
    itemsForm.setFieldsValue({ [a]: value });
  };
  const onAutoCompleteSelect = (data) => {
    const { name, code } = data;
    if (!isNaN(data.tax)) {
      data.tax = String(Math.trunc(data.tax));
    }
    itemsForm.setFieldsValue({ ...data, qty: 1, discount: 0 });
    setTotal({ ...data, qty: 1, discount: 0 });
    setItemName(name);
    setCodeName(code);
  };
  const onHandleChangeText = (e) => {
    let valu = e.target.value;

    if (!Number(valu)) {
      this.setState({ [e.target.name]: valu });
    }
    return;
  };
  return (
    <Form
      className={styles.registerForm}
      form={itemsForm}
      initialValues={{ tax: '21' }}
      onValuesChange={onFormChange}
      onFinish={onSubmit}
      onFinishFailed={({ errorFields }) => {
        itemsForm.scrollToField(errorFields[0].name);
      }}
    >
      <div className={styles.customerColumn}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Unesite naziv!'
            },
            {
              max: 50,
              message: 'Dozvoljeno 50 karaktera!'
            }
          ]}
        >
          <AutoComplete
            autocompleteValue={itemName}
            changeValue={onChangeValue}
            autocompleteName="name"
            options={registers}
            itemAutocomplete={true}
            updateCustomerFields={onAutoCompleteSelect}
            field="name"
            placeHolder="Unesite naziv"
            focus={focus}
          ></AutoComplete>
          {/* <Input placeholder="Naziv" /> */}
        </Form.Item>
      </div>
      <div className={styles.formColumn}>
        {' '}
        <Form.Item
          name="qty"
          rules={[
            {
              required: true,
              message: 'Unesite količinu!'
            }
          ]}
        >
          <InputNumber
            placeholder="Količina"
            formatter={(value) =>
              value
                .toString()
                .replace(',', '.')
                .replace(/[^0-9.]/g, '')
            }
            parser={(input) => input.slice(0, input.indexOf('.') > 0 ? input.indexOf('.') + 4 : input.length)}
            min="0"
          />
        </Form.Item>
      </div>
      <div className={styles.formColumn}>
        {' '}
        <Form.Item
          name="unit"
          normalize={(value) => (value || '').replace(/[^A-Za-z0-9 ^]+/g, '')}
          rules={[
            {
              required: true,
              message: 'Unesite mjeru!'
            }
          ]}
        >
          <Input placeholder="Mjera" />
        </Form.Item>
      </div>
      <div className={styles.formColumn}>
        {' '}
        <Form.Item
          name="unit_price"
          rules={[
            {
              required: true,
              message: 'Unesite cijenu!'
            }
          ]}
        >
          <InputNumber
            placeholder="Cijena"
            formatter={(value) =>
              value
                .toString()
                .replace(',', '.')
                .replace(/[^0-9.]/g, '')
            }
            parser={(input) => input.slice(0, input.indexOf('.') > 0 ? input.indexOf('.') + 5 : input.length)}
            min="0"
          />
        </Form.Item>
      </div>
      <div className={styles.formColumn}>
        {' '}
        <Form.Item
          name="tax"
          rules={[
            {
              required: true,
              message: 'Unesite porez!'
            }
          ]}
        >
          <Select>
            <Option value="21">21%</Option>
            <Option value="15">15%</Option>
            <Option value="7">7%</Option>
            <Option value="0">0%</Option>
            <Option disabled className={styles.cursorPointer}>
              Oslobođenja
            </Option>
            <Option value="VAT_CL17" title="Mjesto prometa usluga">
              VAT_CL17
            </Option>
            <Option value="VAT_CL20" title="Poreska osnovica i ispravka poreske osnovice">
              VAT_CL20
            </Option>
            <Option value="VAT_CL26" title="Oslobođenja od javnog interesa">
              VAT_CL26
            </Option>
            <Option value="VAT_CL27" title="Ostala oslobođenja">
              VAT_CL27
            </Option>
            <Option value="VAT_CL28" title="Oslobođenja kod uvoza proizvoda">
              VAT_CL28
            </Option>
            <Option value="VAT_CL29" title="Oslobođenja kod privremenog uvoza proizvoda">
              VAT_CL29
            </Option>
            <Option value="VAT_CL30" title="Posebna oslobođenja">
              VAT_CL30
            </Option>
          </Select>
        </Form.Item>
      </div>
      <div className={styles.formColumn}>
        {' '}
        <Form.Item
          name="discount"
          initialValue="0"
          rules={[
            {
              required: true,
              message: 'Unesite rabat!'
            }
          ]}
        >
          <InputNumber min={0} max={100} formatter={(value) => String(value).replace(/[^0-9]/g, '') + '%'} customInput={Input} />
        </Form.Item>
      </div>
      <div className={styles.formColumn}>
        {' '}
        <Form.Item
          name="amount"
          rules={[
            {
              required: true,
              message: 'Unesite total!'
            }
          ]}
        >
          <InputNumber
            placeholder="Total"
            formatter={(value) =>
              value
                .toString()
                .replace(',', '.')
                .replace(/[^0-9.]/g, '')
            }
            parser={(input) => input.slice(0, input.indexOf('.') > 0 ? input.indexOf('.') + 5 : input.length)}
            min="0"
          />
        </Form.Item>
      </div>
      <Popover content="Dodaj stavku" trigger="hover">
        <div className={styles.formColumn} style={{ marginLeft: '2.7%' }}>
          {' '}
          <Form.Item>
            <Button htmlType="submit" className={styles.plusButtonItems}>
              <PlusCircleOutlined />
            </Button>
          </Form.Item>
        </div>
      </Popover>
    </Form>
  );
};

const ItemsForm = forwardRef(ItemsFormFunction);

export { ItemsForm };
