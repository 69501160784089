import React from 'react';
import { Table as AntTable, Pagination, Row, Col, Select } from 'antd';
import styles from '../../styles/Registers.module.css';
import { AddOrEditRegister } from './AddOrEditRegister';
import { ActionsTableColCommon } from './ActionsTableColCommon';
import { Arrow } from '../../../components/Arrow';

export const Table = ({
  registers,
  loading,
  totalRows,
  currentPage,
  pageSize,
  onPageSizeChange,
  onPaginationChange,
  onClone,
  onEdit,
  onDelete,
  orderValue,
  setOrderValue
}) => {
  const { Option } = Select;
  const currentStateFrom = currentPage !== 1 ? (currentPage - 1) * pageSize + 1 : 1;
  const currentStateTo = currentPage * pageSize < totalRows ? currentPage * pageSize : totalRows;
  var showing;
  if (totalRows) {
    showing =
      'Prikazano ' + String(currentStateFrom).replace(/^0+/, '') + '-' + String(currentStateTo).replace(/^0+/, '') + ' od ' + totalRows;
  } else {
    showing = '';
  }
  const orderingFunction = (columnKey) => {
    if (orderValue !== columnKey) {
      setOrderValue(columnKey);
    } else {
      setOrderValue('-' + columnKey);
    }
  };
  const renderTitle = (name, title) => {
    return (
      <Row>
        <Col xs={23} sm={23}>
          <span>{title}</span>
        </Col>

        <Col xs={1} sm={1}>
          {!orderValue.endsWith(name) ? (
            <Row style={{ marginTop: '-5px' }}>
              <Col xs={24} sm={24} style={{ height: '10px' }}>
                <Arrow rotate={true} color={'#bbbbc1'} />
              </Col>
              <Col xs={24} sm={24}>
                <Arrow rotate={false} color={'#bbbbc1'} />
              </Col>
            </Row>
          ) : orderValue === name ? (
            <Row>
              <Col xs={24} sm={24}>
                <Arrow rotate={false} color={'#1abc9c'} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={24} sm={24} style={{ height: '10px' }}>
                <Arrow rotate={true} color={'#1abc9c'} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  };
  const renderActions = (record) => {
    return (
      <ActionsTableColCommon
        register={record}
        onClone={onClone}
        onEdit={onEdit}
        onDelete={onDelete}
        renderModal={(commonProps) => <AddOrEditRegister {...commonProps} />}
      />
    );
  };
  const transformRegisters = (data) => {
    return data.map((option) => {
      const { id, code, unit, name, tax, unit_price } = option;
      return {
        id,
        name,
        code,
        unit,
        unit_price:
          parseFloat(unit_price)
            .toFixed(4)
            .replace(/0{1,2}$/, '') + ' €',
        tax: !isNaN(tax) ? parseFloat(tax).toFixed() + '%' : tax
      };
    });
  };
  const columns = [
    {
      title: renderTitle('code', 'Šifra'),
      key: 'code',
      dataIndex: 'code',
      fixed: 'left',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: renderTitle('name', 'Naziv'),
      key: 'name',
      dataIndex: 'name',
      fixed: 'left',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: renderTitle('unit', 'Jedinica mjere'),
      key: 'unit',
      dataIndex: 'unit',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: renderTitle('unit_price', 'Cijena'),
      key: 'unit_price',
      dataIndex: 'unit_price',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: renderTitle('tax', 'Porez'),
      key: 'tax',
      dataIndex: 'tax',
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            orderingFunction(column.key);
          }
        };
      }
    },
    {
      title: 'Akcije',
      key: 'action',
      width: '120',
      fixed: 'right',
      render: (record) => renderActions(record)
    }
  ];
  return (
    <div>
      <div className={styles.registersTable}>
        <AntTable
          columns={columns}
          dataSource={transformRegisters(registers)}
          loading={loading}
          pagination={false}
          locale={{ emptyText: 'Nema rezultata' }}
          scroll={{ x: true }}
          rowKey={({ id }) => id}
        />
      </div>
      <div className={styles.tableFooter}>
        <div className={styles.paginationDesription}>{showing}</div>
        <div className={'ant-table-pagination ant-table-pagination-right ' + styles.paginationBackground}>
          <Pagination total={totalRows} current={currentPage} onChange={onPaginationChange} showSizeChanger={false} pageSize={pageSize} />
          <Select
            className={styles.pageSizeButton}
            defaultValue={String(pageSize)}
            style={{ width: 130, alignSelf: 'end' }}
            onChange={(e) => onPageSizeChange(e)}
          >
            <Option value="10">10 po strani</Option>
            <Option value="20">20 po strani</Option>
            <Option value="50">50 po strani</Option>
            <Option value="100">100 po strani</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};
