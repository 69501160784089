import React, { useState, forwardRef } from 'react';
import 'antd/dist/antd.css';
import { Table, Input, Button, Form, Divider, Row, Popconfirm, Select, InputNumber } from 'antd';
import { ItemsForm } from './ItemsForm';
import styles from '../../styles/Registers.module.css';
import { CalculatorOutlined } from '@ant-design/icons';

import { AddOrEditRegister } from '../../registers/components/AddOrEditRegister';
import Decimal from 'decimal.js-light';

const { Option } = Select;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode =
    dataIndex === 'tax' ? (
      <Select>
        <Option value="21">21%</Option>
        <Option value="15">15%</Option>
        <Option value="7">7%</Option>
        <Option value="0">0%</Option>
        <Option disabled className={styles.cursorPointer}>
          Oslobođenja
        </Option>
        <Option value="VAT_CL17" title="Mjesto prometa usluga">
          VAT_CL17
        </Option>
        <Option value="VAT_CL20" title="Poreska osnovica i ispravka poreske osnovice">
          VAT_CL20
        </Option>
        <Option value="VAT_CL26" title="Oslobođenja od javnog interesa">
          VAT_CL26
        </Option>
        <Option value="VAT_CL27" title="Ostala oslobođenja">
          VAT_CL27
        </Option>
        <Option value="VAT_CL28" title="Oslobođenja kod uvoza proizvoda">
          VAT_CL28
        </Option>
        <Option value="VAT_CL29" title="Oslobođenja kod privremenog uvoza proizvoda">
          VAT_CL29
        </Option>
        <Option value="VAT_CL30" title="Posebna oslobođenja">
          VAT_CL30
        </Option>
      </Select>
    ) : dataIndex == 'qty' || dataIndex == 'unit_price' || dataIndex == 'amount' ? (
      <InputNumber
        formatter={(value) =>
          value
            .toString()
            .replace(',', '.')
            .replace(/[^0-9.]/g, '')
        }
        parser={(input) => input.slice(0, input.indexOf('.') > 0 ? input.indexOf('.') + 5 : input.length)}
        min="0"
      />
    ) : dataIndex == 'discount' ? (
      <InputNumber min={0} max={100} formatter={(value) => String(value).replace(/[^0-9]/g, '') + '%'} customInput={Input} />
    ) : dataIndex == 'unit' ? (
      <Input formatter={(value) => String(value).replace(/[^A-Za-z0-9 ^]+/g, '')} customInput={Input} />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: inputType !== 'select',
              message: 'Obavezno polje!'
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTableFunction = ({ items, setItems, registers, enableEdit }, ref) => {
  const [tableForm] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [dataForModal, setDataForModal] = useState(false);

  const isEditing = (record) => record.key === editingKey;
  const onFormChange = (changedFields, allFields) => {
    const fieldName = Object.keys(changedFields)[0];
    if (['unit_price', 'qty', 'tax', 'discount'].includes(fieldName)) {
      setTotal({ ...allFields });
    } else if (Object.keys(changedFields)[0] == 'amount') {
      if (allFields.qty == 0) {
        allFields.qty = 1;
      }
      setPrice({ ...allFields });
    }
  };
  const setTotal = ({ unit_price = 0, qty = 1, tax = 0, discount = 0 }) => {
    let discountDecimal = !isNaN(parseFloat(discount)) ? new Decimal(1).minus(parseFloat(discount) / 100).valueOf() : 1;
    let taxDecimal = tax && !isNaN(tax) ? new Decimal(1).plus(tax / 100).valueOf() : 1;
    let amount = new Decimal(!isNaN(parseFloat(unit_price)) ? parseFloat(unit_price) : 0)
      .times(!isNaN(parseFloat(qty)) ? parseFloat(qty) : 1)
      .times(discountDecimal)
      .times(taxDecimal)
      .toFixed(4)
      .replace(/0{1,2}$/, '');

    tableForm.setFieldsValue({ amount, qty });
  };
  const setPrice = ({ amount = 0, qty = 1, tax = 0, discount = 0 }) => {
    let discountDecimal = !isNaN(parseFloat(discount)) ? new Decimal(1).minus(parseFloat(discount) / 100).valueOf() : 1;
    let taxDecimal = tax && !isNaN(tax) ? new Decimal(1).plus(tax / 100).valueOf() : 1;
    let unit_price = new Decimal(!isNaN(parseFloat(amount)) ? parseFloat(amount) : 0)
      .dividedBy(taxDecimal)
      .dividedBy(discountDecimal)
      .dividedBy(!isNaN(parseFloat(qty)) ? parseFloat(qty) : 1)
      .toFixed(4)
      .replace(/0{1,2}$/, '');
    tableForm.setFieldsValue({ unit_price, qty });
  };
  const edit = (record) => {
    tableForm.setFieldsValue({
      color: '',
      label: '',
      memo: '',
      ...record
    });
    setEditingKey(record.key);
  };

  const deleteRow = (key) => {
    const filteredItems = items.filter((item) => {
      return item.key != key;
    });
    filteredItems.forEach((item, index) => {
      item.key = index + 1;
    });
    setItems(filteredItems);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const saveItem = async ({ key, values }) => {
    if (!isNaN(key)) {
      try {
        const row = await tableForm.validateFields();
        const newData = [...items];
        const index = newData.findIndex((item) => key === item.key);

        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
          setItems(newData);
          setEditingKey('');
        } else {
          newData.push(row);
          setItems(newData);
          setEditingKey('');
        }
      } catch (errInfo) {
        //console.log('Validate Failed:', errInfo);
      }
    } else {
      const newData = [...items];
      const index = items.length;
      const taxDecimal = values.tax && !isNaN(values.tax) ? values.tax : 0;
      values.key = items.length + 1;

      values.amount =
        values.unit_price *
        parseFloat(values.qty).toFixed(4) *
        ((100.0 - parseFloat(values.discount)).toFixed(4) / 100.0) *
        ((100.0 + parseFloat(taxDecimal)).toFixed(4) / 100.0);
      values.amount = values.amount.toFixed(4);
      newData.push(values);
      setItems(newData);
      setEditingKey('');
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      align: 'center',
      editable: false,
      width: '2%'
    },
    {
      title: 'Naziv',
      align: 'left',
      dataIndex: 'name',
      editable: true,
      width: '22%'
    },
    {
      title: 'Količina',
      align: 'center',
      inputType: 'number',
      dataIndex: 'qty',
      editable: true,
      width: '10%'
    },
    {
      title: 'Mjera',
      align: 'center',
      dataIndex: 'unit',
      editable: true,
      width: '10%'
    },
    {
      title: 'Cijena',
      align: 'center',
      inputType: 'number',
      render: (unit_price) =>
        parseFloat(unit_price)
          .toFixed(4)
          .replace(/0{1,2}$/, '') + '€',
      dataIndex: 'unit_price',
      editable: true,
      width: '10%'
    },

    {
      title: 'Porez',
      align: 'center',
      dataIndex: 'tax',
      render: (tax) => {
        if (tax && !isNaN(tax)) {
          return Math.trunc(tax) + '%';
        } else {
          return tax;
        }
      },
      editable: true,
      width: '10%',
      inputType: 'select'
    },

    {
      title: 'Rabat',
      align: 'center',
      inputType: 'number',
      render: (discount) => discount + '%',
      dataIndex: 'discount',
      editable: true,
      width: '11%'
    },

    {
      title: 'Ukupno',
      align: 'center',
      inputType: 'number',
      render: (amount) => {
        if (1) {
          return (
            parseFloat(amount)
              .toFixed(4)
              .replace(/0{1,2}$/, '') + '€'
          );
        } else {
          return 1;
        }
      },
      dataIndex: 'amount',
      editable: true,
      width: '11%'
    }
  ];
  if (enableEdit) {
    columns.push({
      title: 'Akcije',
      align: 'center',
      dataIndex: 'operation',
      width: '14%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span
            style={{
              display: 'flex'
            }}
          >
            <Button type="link" onClick={() => saveItem({ key: record.key })}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#707070"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                <polyline points="17 21 17 13 7 13 7 21"></polyline>
                <polyline points="7 3 7 8 15 8"></polyline>
              </svg>
            </Button>
            <Button type="link" onClick={cancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#707070"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                <line x1="18" y1="9" x2="12" y2="15"></line>
                <line x1="12" y1="9" x2="18" y2="15"></line>
              </svg>
            </Button>
          </span>
        ) : (
          <span>
            <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)} style={{ padding: '4px 4px' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#707070"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
              </svg>
            </Button>
            <Button
              type="link"
              disabled={editingKey !== ''}
              onClick={() => {
                setDataForModal(record);
                setVisibleModal(true);
              }}
              style={{ padding: '4px 4px' }}
            >
              <CalculatorOutlined className={styles.addToRegisters} />
            </Button>

            <Popconfirm
              placement="leftTop"
              title={'Jeste li sigurni da želite obrisati upis?'}
              onConfirm={() => deleteRow(record.key)}
              okText="Da"
              cancelText="Ne"
            >
              <Button type="link" disabled={editingKey !== ''} style={{ padding: '4px 4px' }}>
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#707070"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </Button>
            </Popconfirm>
          </span>
        );
      }
    });
  }
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType || 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });
  return (
    <>
      <Form form={tableForm} component={false} onValuesChange={onFormChange}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          bordered={false}
          dataSource={items}
          columns={mergedColumns}
          style={{ width: '100%' }}
          pagination={false}
          className={styles.itemsTable}
        />
      </Form>
      <Divider className={styles.invoiceItemFormDivider}></Divider>
      <Row>{enableEdit ? <ItemsForm saveItem={saveItem} registers={registers} itemAutocomplete={true} ref={ref}></ItemsForm> : ''}</Row>
      <AddOrEditRegister
        visibleModal={visibleModal}
        onCloseModal={() => setVisibleModal(false)}
        register={dataForModal}
        fromInvoice={true}
      />
    </>
  );
};

const EditableTable = forwardRef(EditableTableFunction);

export { EditableTable };
